import { PageContainer } from '../../components/PageContainer';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

export const ProductsAndServicesonPurchase = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        link1="Purchase and Payments"
        to2="/purchase-and-payments/products-and-services"
        link2="Payment Setup"
      />
      {/* change this to main content */}
      <HeaderContainer>This is Products and Services</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
