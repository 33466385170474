import { PageContainer } from '../../components/PageContainer';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

export const ChartsofAccountsToDate = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        link1="Accounting"
        to2="/accounting/charts-of-accounts-to-date"
        link2="Charts of Accounts to Date"
      />
      <HeaderContainer> Charts of Accounts to Date </HeaderContainer>
    </PageContainer>
  );
};
