import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line import/order
//import { ToastContainer, toast } from 'react-toastify';

import { Header } from './Header/Header';
import { SideBar } from './SideBar/SideBar';

//import { ToggleButtonContainer } from '@components/ButtonSwitchContainer/ButtonToggleContainer';
//import { Moon } from '@components/ButtonSwitchContainer/React-Icon/Moon';
import { SideBarIcon } from '@components/ButtonSwitchContainer/React-Icon/SideBarIcon';
//import { Sun } from '@components/ButtonSwitchContainer/React-Icon/Sun';

interface IProps {
  children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [sideBarBot, setSideBar] = useState(false);

  const handleSideBarButton = () => {
    setShowSideBar(!showSideBar);
    setSideBar(true);
  };

  const setSideBarClass = sideBarBot ? '' : 'rotate-0';

  // const notifyLight = () => {
  //   toast.info('Let There Be Light', {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //     theme: 'colored',
  //     autoClose: 10,
  //   });
  // };

  // const notifyDark = () => {
  //   toast.info('Lets Get Dark', {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //     theme: 'colored',
  //     autoClose: 10,
  //   });
  // };

  //const [letThereBeLightBut, setLetThereBeLightBut] = useState(true);
  //const [bgColor, setBgColor] = useState('#E0E0E0');
  const bgColor = '#E0E0E0';
  //const [letsGetDarkBut, setLetsGetDarkBut] = useState(false);

  // const handleLetTherebeLightBut = () => {
  //   notifyLight();
  //   setBgColor('#FAFAFA');
  //   setLetThereBeLightBut(true);
  //   setLetsGetDarkBut(false);
  // };

  // const selLetThereBeLightButClass = letThereBeLightBut
  //   ? 'stroke-[#FCE570] stroke-1 px-3 rounded-full border-2 border-gray-200 bg-black shadow-md'
  //   : 'stroke-black px-3 rounded-full';

  // const handleLetsDarkBut = () => {
  //   notifyDark();
  //   setBgColor('#909090');
  //   setLetThereBeLightBut(false);
  //   setLetsGetDarkBut(true);
  // };

  // const selLetsDarkButClass = letsGetDarkBut
  //   ? 'stroke-white stroke-1 px-3 rounded-full border-2 border-gray-200 bg-black shadow-md'
  //   : 'stroke-black px-3 rounded-full';

  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
  }, [bgColor]);

  return (
    <>
      <div className="h-full w-full">
        <div className="grid h-full gap-0 grid-cols-6">
          {showSideBar && (
            <div className="">
              <SideBar />
            </div>
          )}
          {/* <div className="col-span-1 h-full border border-blue-900 bg-blue-500">
            1
          </div> */}
          {/* This is the HeaderSide */}
          <div
            className={`col-span-6 h-full ${
              showSideBar ? 'col-span-5' : 'col-span-6'
            }`}
          >
            {/* light botton */}
            {/* <div className="px-4 py-2">
              <ToggleButtonContainer>
                <button
                  onClick={() => handleLetTherebeLightBut()}
                  className={`${selLetThereBeLightButClass}`}
                >
                  <Sun height="24" width="24" />
                </button>
                <ToastContainer />
                <button
                  onClick={() => handleLetsDarkBut()}
                  className={`${selLetsDarkButClass}`}
                >
                  <Moon height="24" width="24" />
                </button>
              </ToggleButtonContainer>
            </div> */}
            {/* light button */}
            <div className=" pt-4 pb-6 px-4">
              <div className="flex justify-between bg-white pt-4 px-4 rounded-t-md">
                <button
                  className={`${setSideBarClass}`}
                  onClick={handleSideBarButton}
                >
                  <SideBarIcon height="30" width="30" />
                </button>
              </div>
              {/* This is the Layout */}
              <Header />
            </div>
            {/* ----------------------- */}
            <div className="mx-4 ">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
