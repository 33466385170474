import { PageContainer } from '../../components/PageContainer';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

export const EstimateAndQuotes = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        link1="Revenue and Collections"
        to2="/revenue-and-collections/estimate-and-quotes"
        link2="Estimate and Quotes"
      />
      {/* change this to main content */}
      <HeaderContainer>This is Estimate and Quotes</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
