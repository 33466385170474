import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelLinkButton } from '@components/Form';
import { Input } from '@components/Form/Input';
import { Loading } from '@components/Loading';
import { Space } from '@components/Space';
import { routes } from '@config/routes';
import { CustomerViewModel, customerDefaultValue } from '@models/Customer';
import { CustomerService } from '@services/Customer';

export const CustomerDetails: React.FC = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState(customerDefaultValue);
  const navigate = useNavigate();

  const loadData = () => {
    CustomerService.getById(Number(id))
      .then((response) => response.json() as Promise<CustomerViewModel>)
      .then((result) => {
        if (result.id) {
          setCustomer(result);
          setIsLoading(false);
          console.log('Success: Fetch the record.');
        } else {
          console.log('Error: Failed to get record.');
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle thr request');
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const handleNameClick = (customerId: number) => {
    navigate(`${routes.CUSTOMERS}/${customerId}/details`);
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <Box size="medium">
          <Formik
            initialValues={customer}
            validateOnBlur={true}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
          >
            {() => {
              return (
                <>
                  <form>
                    <FormSectionContainer>
                      <Input
                        label="Name"
                        name="name"
                        onClick={() => handleNameClick(customer.id)}
                      />
                      <Input label="Notes" name="notes" />
                      <Input label="Account Number" name="accountNumber" />
                      <Input label="Website" name="website" />
                      <Space />
                      <h3 className="text-lg font-medium leading-6 text-green-900">
                        Shipping Information
                      </h3>
                      <Space />
                      <Input label="Name" name="shipToName" />
                      <Input label="Address" name="shipToAddress1" />
                      <Input label="Address (optional)" name="shipToAddress2" />
                      <Input label="Country" name="shipToAddressCountry" />
                      <Input label="State" name="shipToAddressState" />
                      <Input label="Phone" name="shipToPhone" />
                      <Input label="Remarks" name="shipToRemarks" />
                    </FormSectionContainer>
                    <FormButtonsContainer>
                      <CancelLinkButton to={`${routes.CUSTOMERS}`} />
                      <button
                        className="bg-[#58993C] w-20 ml-4 rounded-md "
                        onClick={() =>
                          navigate(`${routes.CUSTOMERS}/${customer.id}/edit`)
                        }
                      >
                        Edit
                      </button>
                    </FormButtonsContainer>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
};
