import avatar02 from './avatar02.png';
import { ButtonCLick } from './ButtonCLicker/ButtonClick';

import { BellIcon } from '@components/ButtonSwitchContainer/React-Icon/BellIcon';
import { EnvelopIcon } from '@components/ButtonSwitchContainer/React-Icon/EnvelopIcon';

export const Header = () => {
  return (
    <>
      <header className="bg-white px-6 rounded-b-md pb-6">
        <div className="flex justify-between">
          <div className="w-64" />

          <div className=" text-3xl text-green-700">SimpAcc Accounting</div>
          <div className="flex flex-row-reverse items-center">
            <div className="flex">
              <div className="space-x-3 inline-block pr-2 pt-0.5">
                <ButtonCLick>
                  <BellIcon height="20" width="20" />
                </ButtonCLick>
                <ButtonCLick>
                  <EnvelopIcon height="20" width="20" />
                </ButtonCLick>
              </div>
              <div className=" pr-4 ">
                <button className="">Claudia Alves</button>

                <div className="flex justify-end">
                  <span className="text-[#58993C]">(admin)</span>
                </div>
              </div>
              <div>
                <img src={avatar02} alt={'DP'} height="50" width="50" />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
