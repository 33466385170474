interface Props {
  children?: React.ReactNode;
}

export const ButtonCLick: React.FC<Props> = ({ children }) => {
  return (
    <button className="text-md items-center rounded-full text-gray-400 hover:text-gray-700">
      {children}
    </button>
  );
};
