import { PageContainer } from '../../components/PageContainer';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

export const CollectionSetUp = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        link1="Revenue and Collections"
        to2="/revenue-and-collections/collection-set-up"
        link2="Collection Setup"
      />
      {/* change this to main content */}
      <HeaderContainer>This is Collection Setup</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
