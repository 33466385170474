import { PageContainer } from '../../components/PageContainer';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

export const PaymentsSetup = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        link1="Purchase and Payments"
        to2="/purchase-and-payments/payment-set-up"
        link2="Payment Setup"
      />
      {/* change this to main content */}
      <HeaderContainer>This is Payment Set Up</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
