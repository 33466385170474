import { registerLicense } from '@syncfusion/ej2-base';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { AppRouter } from './AppRouter';
import './index.css';

// Registering Syncfusion license key
registerLicense(
  'ORg4AjUWIQA/Gnt2VlhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9TdEViXnxadHJXQ2Zd'
);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<RouterProvider router={AppRouter} />);
