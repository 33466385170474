interface Props {
  children?: React.ReactNode;
}

export const TableContainers: React.FC<Props> = ({ children }) => {
  return (
    <div className="inline-block w-full rounded-lg shadow">
      <table className="border rounded-lg  w-full table-fixed">
        {children}
      </table>
    </div>
  );
};
