import { Outlet } from 'react-router-dom';

import { PageContent } from '@components/Page';

export const RevenueAndCollections = () => {
  return (
    <>
      {/* <PageHeading>Revenue and Collections</PageHeading> */}

      <PageContent>
        <Outlet />
      </PageContent>
    </>
  );
};
