import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { AddRecord } from '@components/ActionButtons/AddRecord';
import { Pagination } from '@components/ActionButtons/Pagination';
import { BillItemsList } from '@models/BillItemList';
import { billList } from '@models/BillList.ts';
import { Computes } from 'src/modules/accounting/components/Computes/Computes';
import { Filters } from 'src/modules/accounting/components/Filters';
import { InputSearchbar } from 'src/modules/accounting/components/InputSearchbar';
import { Sortby } from 'src/modules/accounting/components/Sortby';
import { TableContainers } from 'src/modules/accounting/components/TableContainers/TableContainer';
import { TopRowsContainer } from 'src/modules/accounting/components/TableContainers/TopRowsContainer';

export const BillItems = () => {
  return (
    <>
      <div className="bg-white pt-8 h-full w-full shadow-lg border rounded-lg">
        <BreadCrumbs
          link1="Purchase and Payments"
          to2="/purchase-&-payments/bills"
          link2="Bills"
          to3="/purchase-&-payments/bills/bill-items"
          link3="BillItems"
        />
        <div className="grid justify-items-center pt-4">
          <h2 className="sr-only">Steps</h2>
          {/* Steps code here */}
          <div className="pt-4 relative after:absolute after:inset-x-0 after:top-1/2 after:block after:h-0.5 after:-translate-y-1/2 after:rounded-lg after:bg-gray-100">
            <ol className="relative space-x-52 z-10 flex justify-between text-sm font-medium text-gray-500">
              <li className="flex items-center gap-2 bg-white pb-4">
                <span className="h-6 w-6 rounded-full border-green-400 bg-green-600 border text-center text-[10px]/6 font-bold text-white">
                  1
                </span>
              </li>

              <li className="flex items-center gap-2 bg-white pb-4">
                <span className="h-6 w-6 rounded-full border-green-400 bg-green-600  border text-center text-[10px]/6 font-bold text-white">
                  2
                </span>
              </li>

              <li className="flex items-center gap-2 bg-white pb-4">
                <span className="h-6 w-6 rounded-full bg-green-600 border-green-400 border text-center text-[10px]/6 font-bold text-white">
                  3
                </span>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <h1 className="text-3xl pt-8 text-center text-green-700">
            Bill Items
          </h1>
        </div>

        <div className="flex justify-center px-32 pt-10">
          <div className="px-8 mx-auto sm:px-8 py-3 overflow-hidden ">
            <div className="text-end">
              <form className="flex flex-col w-3/4 max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
                <InputSearchbar placeholder="Search here ..." />
                <Filters placeholder="Fltr" />
                <Sortby placeholder="Sort by" />
              </form>
            </div>
            <div className=" py-3">
              <div className="px-4 w-full sm:-mx-8 sm:px-8">
                <TableContainers>
                  <thead>
                    <tr>
                      <TopRowsContainer>Items</TopRowsContainer>
                      <TopRowsContainer>Description</TopRowsContainer>
                      <TopRowsContainer>Qty</TopRowsContainer>
                      <TopRowsContainer>Price</TopRowsContainer>
                      <TopRowsContainer>Account</TopRowsContainer>
                      <TopRowsContainer>Tax</TopRowsContainer>
                      <TopRowsContainer>Ammount</TopRowsContainer>
                      <TopRowsContainer>Actions</TopRowsContainer>
                    </tr>
                  </thead>
                  <tbody>
                    {billList.map((current, index) => (
                      <BillItemsList
                        key={current.id}
                        item={current}
                        className={
                          index % 2 === 0
                            ? 'text-sm text-center space-y-3 text-gray-800 uppercase  border-b border-gray-200 bg-green-100'
                            : 'text-sm text-center space-y-3 text-gray-800 uppercase  border-b border-gray-200 bg-white'
                        }
                      />
                    ))}
                  </tbody>
                </TableContainers>
                <AddRecord to="/purchase-&-payments/vendors">
                  Add Billing Record
                </AddRecord>
                <div className="flex justify-end">
                  <tfoot className="text-lg">
                    <Computes
                      className="text-gray-500"
                      label="Subtotal"
                      amount="$8,000"
                    />
                    <Computes
                      className="text-gray-500"
                      label="Tax"
                      amount="$1,760"
                    />
                    <Computes
                      className="text-gray-900 font-semibold"
                      label="Total"
                      amount="$10,560"
                    />
                  </tfoot>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-2 pb-10 pr-48">
          <Pagination to="/purchase-and-payments/bills">Previous</Pagination>
          <Pagination to="">Save</Pagination>
        </div>
      </div>
    </>
  );
};
