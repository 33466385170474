import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
  Toolbar,
  EditSettingsModel,
  Grid,
  DataStateChangeEventArgs,
  DataResult,
} from '@syncfusion/ej2-react-grids';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomerTableViewModel, CustomerViewModel } from '@models/Customer';
import { CustomerService } from '@services/Customer';
import { routes } from 'src/config/routes';

export const CustomerList: React.FC = () => {
  const navigate = useNavigate();
  let grid: Grid | null;
  let data;

  const editOptions: EditSettingsModel = {
    allowAdding: true,
    allowDeleting: true,
    allowEditing: true,
    mode: 'Dialog',
  };

  const toolbarOptions = [
    {
      text: 'Add',
      tooltipText: 'Add new record',
      prefixIcon: 'e-add',
      id: 'add',
    },
    {
      text: 'Details',
      tooltipText: 'Vew selected record details',
      prefixIcon: 'e-edit',
      id: 'details',
    },
    {
      text: 'Edit',
      tooltipText: 'Edit selected record',
      prefixIcon: 'e-edit',
      id: 'edit',
    },
    {
      text: 'Delete',
      tooltipText: 'Delete selected record',
      prefixIcon: 'e-delete',
      id: 'delete',
    },
  ];

  const clickHandler = (args: any) => {
    if (args.item.id === 'add') {
      navigate(`${routes.CUSTOMERS}/create`);
    } else if (args.item.id === 'edit') {
      const selectedrecords: CustomerViewModel[] =
        grid?.getSelectedRecords() as CustomerViewModel[];
      if (selectedrecords.length > 0) {
        navigate(`${routes.CUSTOMERS}/${selectedrecords[0].id}/edit`);
      }
    } else if (args.item.id === 'details') {
      const selectedrecords: CustomerViewModel[] =
        grid?.getSelectedRecords() as CustomerViewModel[];
      if (selectedrecords.length > 0) {
        navigate(`${routes.CUSTOMERS}/${selectedrecords[0].id}/details`);
      }
      // navigate(`${routes.CUSTOMERS}/details`);
    } else if (args.item.id === 'delete') {
      const selectedrecords: CustomerViewModel[] =
        grid?.getSelectedRecords() as CustomerViewModel[];

      if (selectedrecords.length > 0) {
        CustomerService.delete(selectedrecords[0].id).then((response) => {
          if (response.ok) {
            console.log('deleted successfully');
            grid?.refresh();
          }
        });
      }
    }
  };
  const onNameClick = (id: number) => {
    navigate(`${routes.CUSTOMERS}/${id}/details`);
  };

  const gridNameTemplate = (props: any) => {
    return <div onClick={() => onNameClick(props.id)}>{props.name}</div>;
  };
  function renderComplete() {
    if (
      grid &&
      grid.dataSource instanceof Array &&
      !(grid.dataSource as object[]).length
    ) {
      const state = { skip: 0, take: 10 };
      dataStateChange(state);
    }
  }

  function dataStateChange(state: DataStateChangeEventArgs) {
    execute(state).then((gridData) => {
      if (grid) {
        grid.dataSource = gridData;
      }
    });
  }

  function execute(state: DataStateChangeEventArgs): Promise<DataResult> {
    return getData(state);
  }

  function getData(state: DataStateChangeEventArgs): Promise<DataResult> {
    const page =
      !state || !state.skip || !state.take ? 1 : state.skip / state?.take + 1;

    return CustomerService.getList(page, state.take)
      .then((response) => response.json() as Promise<CustomerTableViewModel>)
      .then((data) => {
        return { result: data.data, count: data.total };
      });
  }

  return (
    <>
      <GridComponent
        dataBound={renderComplete}
        dataSource={data}
        ref={(g) => (grid = g)}
        allowPaging={true}
        pageSettings={{ pageSize: 10 }}
        allowFiltering={true}
        editSettings={editOptions}
        toolbar={toolbarOptions}
        toolbarClick={clickHandler}
        dataStateChange={dataStateChange}
      >
        <ColumnsDirective>
          <ColumnDirective field="id" width="50" headerText="ID" />
          <ColumnDirective
            field="name"
            headerText="Name"
            template={gridNameTemplate}
          />
          <ColumnDirective field="accountNumber" headerText="Account Number" />
        </ColumnsDirective>
        <Inject services={[Page, Toolbar]} />
      </GridComponent>
    </>
  );
};
