export const billList = [
  {
    id: '1',
    items: '1',
    description: 'Nike Shoes',
    qty: '3',
    price: '$12,000.00',
    account: '1234 5678 9123 4567',
    tax: '12%',
    amount: '$31,680.00',
  },

  {
    id: '2',
    items: '2',
    description: 'Nike Shoes',
    qty: '3',
    price: '$12,000.00',
    account: '1234 5678 9123 4567',
    tax: '12%',
    amount: '$31,680.00',
  },
  {
    id: '3',
    items: '3',
    description: 'Nike Shoes',
    qty: '3',
    price: '$12,000.00',
    account: '1234 5678 9123 4567',
    tax: '12%',
    amount: '$31,680.00',
  },

  {
    id: '1',
    items: '2',
    description: 'Nike Shoes',
    qty: '3',
    price: '$12,000.00',
    account: '1234 5678 9123 4567',
    tax: '12%',
    amount: '$31,680.00',
  },
  {
    id: '1',
    items: '2',
    description: 'Nike Shoes',
    qty: '3',
    price: '$12,000.00',
    account: '1234 5678 9123 4567',
    tax: '12%',
    amount: '$31,680.00',
  },
  {
    id: '1',
    items: '2',
    description: 'Nike Shoes',
    qty: '3',
    price: '$12,000.00',
    account: '1234 5678 9123 4567',
    tax: '12%',
    amount: '$31,680.00',
  },
  {
    id: '1',
    items: '2',
    description: 'Nike Shoes',
    qty: '3',
    price: '$12,000.00',
    account: '1234 5678 9123 4567',
    tax: '12%',
    amount: '$31,680.00',
  },
];
