import { CustomerViewModel } from '@models/Customer';
import { getDataUrl } from '@utils/dataUrls';
import { api } from 'src/config/apiEndpoints';

export const CustomerService = {
  create: async function (customer: CustomerViewModel) {
    const createUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}`;
    const createOptions = {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body: JSON.stringify(customer),
    };

    return fetch(createUrl, createOptions);
  },
  delete: async function (id: number) {
    const deleteURL = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}`;
    const deleteOptions = {
      method: 'DELETE',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }),
    };

    return fetch(deleteURL, deleteOptions);
  },
  getById: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}`;
    const dataOptions = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }),
    };

    return fetch(dataUrl, dataOptions);
  },
  getList: async function (page?: number, pageSize?: number) {
    const dataUrl = getDataUrl(
      api.BASE_URL,
      api.CUSTOMERS_ENDPOINT,
      page,
      pageSize,
      undefined,
      undefined
    );

    const dataOptions = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }),
    };
    return fetch(dataUrl, dataOptions);
  },
  update: async function (id: number, customer: CustomerViewModel) {
    const updateUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}`;
    const updateOptions = {
      method: 'PUT',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body: JSON.stringify(customer),
    };

    return fetch(updateUrl, updateOptions);
  },
};
