import { Link } from 'react-router-dom';

import { HomeIcon } from '@components/ButtonSwitchContainer/React-Icon/HomeIcon';

interface Props {
  link1?: string;
  link2?: string;
  link3?: string;
  link4?: string;
  to?: string;
  to2?: string;
  to3?: string;
  to4?: string;
}

export const BreadCrumbs: React.FC<Props> = ({
  link1,
  link2,
  link3,
  link4,
  to,
  to2,
  to3,
  to4,
}) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="mx-4 flex w-full max-w-screen-xl space-x-2 ">
        <li className="flex">
          <div className="flex items-center">
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <HomeIcon height="10" width="10" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>

        <li className="flex">
          <div className="flex items-center">
            <svg
              className="h-6 w-3 flex-shrink-0 text-gray-200"
              viewBox="0 0 24 44"
              preserveAspectRatio="none"
              fill="gray"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
          </div>
        </li>

        <li className="flex">
          <div className="flex items-center">
            <Link
              className="text-[#58993C]  block transition hover:text-green-600 font-bold"
              to={to}
            >
              {link1}
            </Link>
          </div>
        </li>

        {link2 && (
          <>
            <li className="flex">
              <div className="flex items-center">
                <svg
                  className="h-6 w-3 flex-shrink-0 text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="gray"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
              </div>
            </li>

            <li className="flex">
              <div className="flex items-center">
                <Link
                  className="text-[#58993C]  block transition hover:text-green-600 font-bold"
                  to={to2}
                >
                  {link2}
                </Link>
              </div>
            </li>
          </>
        )}

        {link3 && (
          <>
            <li className="flex">
              <div className="flex items-center">
                <svg
                  className="h-6 w-3 flex-shrink-0 text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="gray"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
              </div>
            </li>

            <li className="flex">
              <div className="flex items-center">
                <Link
                  className="text-[#58993C]  block transition hover:text-green-600 font-bold"
                  to={to3}
                >
                  {link3}
                </Link>
              </div>
            </li>
          </>
        )}

        {link4 && (
          <>
            <li className="flex">
              <div className="flex items-center">
                <svg
                  className="h-6 w-3 flex-shrink-0 text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="gray"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
              </div>
            </li>

            <li className="flex">
              <div className="flex items-center">
                <Link
                  className="text-[#58993C]  block transition hover:text-green-600 font-bold"
                  to={to4}
                >
                  {link4}
                </Link>
              </div>
            </li>
          </>
        )}
      </ol>
    </nav>
  );
};
