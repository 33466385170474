interface Props {
  placeholder: string;
}

export const Sortby: React.FC<Props> = ({ placeholder }) => {
  return (
    <div className=" relative ">
      <input
        type="text"
        id='"form-subscribe-Filter'
        className="rounded-lg border-green-400- flex-initial w-32 appearance-none border border-green-300  py-2 pl-4 pr-10 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
        placeholder={placeholder}
      />
      <button className="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="absolute inset-y-1 right-1 z-20 hidden w-8 h-8 ml-4 text-green-500 pointer-events-none  group-hover:text-green-400 sm:block"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
          />
        </svg>
      </button>
    </div>
  );
};
