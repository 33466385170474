interface Props {
  label: string;
  amount: string;
  className?: string;
}

export const Computes: React.FC<Props> = ({ label, amount, className }) => {
  return (
    <>
      <tr className={className}>
        <th
          scope="row"
          colSpan={3}
          className="hidden pl-4 pr-3 text-right text-sm sm:table-cell sm:pl-0"
        >
          {label}
        </th>

        <td className="pl-3 pr-4 text-right text-sm sm:pr-0">{amount}.00</td>
      </tr>
    </>
  );
};
