import { PageContainer } from '../../components/PageContainer';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

export const Settings = () => {
  return (
    <PageContainer>
      <BreadCrumbs to="/settings" link1="Settings" />
      {/* change this to main content */}
      <HeaderContainer>This is Settings Page</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
