import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelLinkButton, SubmitButton } from '@components/Form';
import { Input } from '@components/Form/Input';
import { Space } from '@components/Space';
import { CustomerValidation, customerDefaultValue } from '@models/Customer';
import { CustomerService } from '@services/Customer';
import { routes } from 'src/config/routes';

export const CustomerCreate: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box size="medium">
        <Formik
          initialValues={customerDefaultValue}
          validationSchema={CustomerValidation}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values, actions) => {
            CustomerService.create(values)
              .then((response) => {
                if (response.status == 201) {
                  navigate(`${routes.CUSTOMERS}`);
                } else {
                  console.log('Error: Failed to create a record.');
                }
              })
              .catch((error) => {
                alert('Error: Failed to handle the request.');
                console.log(error);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => {
            return (
              <>
                <form method="POST" onSubmit={formikProps.handleSubmit}>
                  <FormSectionContainer>
                    <Input label="Name" name="name" />
                    <Input label="Notes" name="notes" />
                    <Input label="Account Number" name="accountNumber" />
                    <Input label="Website" name="website" />
                    <Space />
                    <h3 className="text-lg font-medium leading-6 text-green-900">
                      Shipping Information
                    </h3>
                    <Space />
                    <Input label="Name" name="shipToName" />
                    <Input label="Address" name="shipToAddress1" />
                    <Input label="Address (optional)" name="shipToAddress2" />
                    <Input label="Country" name="shipToAddressCountry" />
                    <Input label="State" name="shipToAddressState" />
                    <Input label="Phone" name="shipToPhone" />
                    <Input label="Remarks" name="shipToRemarks" />
                  </FormSectionContainer>
                  <FormButtonsContainer>
                    <CancelLinkButton to={`${routes.CUSTOMERS}`} />

                    <SubmitButton
                      label="Save"
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid
                      }
                    />
                  </FormButtonsContainer>
                </form>
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};
