import { PageContainer } from '../../components/PageContainer';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';

import { AddRecord } from '@components/ActionButtons/AddRecord.tsx';
import { list } from 'src/models/ItemList.ts';
import { ItemList } from 'src/models/ItemList.tsx';

export const Transactions = () => {
  return (
    <>
      <PageContainer>
        <BreadCrumbs
          link1="Purchase and Payments"
          to2="/accounting/transactions"
          link2="Transactions"
        />
        <div className="text-3xl text-center text-green-700">Transactions</div>

        <div className="flex justify-center ">
          <div className="px-4 mx-auto sm:px-8 py-3">
            <div className="text-end">
              <form className="flex flex-col w-3/4 max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
                <div className="relative">
                  <input
                    type="text"
                    id='"form-subscribe-Filter'
                    className="rounded-lg border-green-400- flex-initial appearance-none border border-green-300 w-52 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
                    placeholder="Search here ..."
                  />
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="absolute inset-y-1 right-1 z-20 hidden w-8 h-8 ml-4 text-green-500 pointer-events-none  group-hover:text-green-400 sm:block"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </span>
                </div>
                <div className=" relative ">
                  <input
                    type="text"
                    id='"form-subscribe-Filter'
                    className="rounded-lg border-green-400- flex-initial w-32 appearance-none border border-green-300  py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
                    placeholder="fltr"
                  />
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="absolute inset-y-1 right-1 z-20 hidden w-8 h-8 ml-4 text-green-500 pointer-events-none  group-hover:text-green-400 sm:block"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                      />
                    </svg>
                  </span>
                </div>
                <div className=" relative ">
                  <input
                    type="text"
                    id='"form-subscribe-Filter'
                    className="rounded-lg border-green-400- flex-initial w-32 appearance-none border border-green-300  py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
                    placeholder="Sort by"
                  />
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="absolute inset-y-1 right-1 z-20 hidden w-8 h-8 ml-4 text-green-500 pointer-events-none  group-hover:text-green-400 sm:block"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                      />
                    </svg>
                  </span>
                </div>
              </form>
            </div>
            <div className=" py-3">
              <div className="px-4 w-full sm:-mx-8 sm:px-8">
                <div className="inline-block w-full rounded-lg shadow">
                  <table className="border rounded-lg  w-full table-fixed">
                    <thead>
                      <tr>
                        <th className="bg-green-300 py-3 text-sm font-semibold text-center text-gray-800 uppercase  border-b border-gray-200">
                          Date
                        </th>
                        <th className="bg-green-300 py-3 text-sm font-semibold text-center text-gray-800 uppercase  border-b border-gray-200">
                          Due Date
                        </th>
                        <th className="bg-green-300 py-3 text-sm font-semibold text-center text-gray-800 uppercase  border-b border-gray-200">
                          Currency
                        </th>
                        <th className="bg-green-300 py-3 text-sm font-semibold text-center text-gray-800 uppercase  border-b border-gray-200">
                          Company
                        </th>
                        <th className="bg-green-300 py-3 text-sm text-center text-gray-800 uppercase  border-b border-gray-200 font-semibold">
                          PO number
                        </th>
                        <th className="bg-green-300 py-3 text-sm font-semibold text-center text-gray-800 uppercase  border-b border-gray-200">
                          Amount
                        </th>
                        <th className="bg-green-300 py-3 text-sm font-semibold text-center text-gray-800 uppercase  border-b border-gray-200">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((current, index) => (
                        <ItemList
                          key={current.id}
                          item={current}
                          className={
                            index % 2 === 0
                              ? 'text-sm text-center space-y-3 text-gray-800 uppercase  border-b border-gray-200 bg-green-100'
                              : 'text-sm text-center space-y-3 text-gray-800 uppercase  border-b border-gray-200 bg-white'
                          }
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                <AddRecord to="/purchase-and-payments/vendors">
                  Add Billing Record
                </AddRecord>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
};
