interface Props {
  placeholder: string;
}

export const Filters: React.FC<Props> = ({ placeholder }) => {
  return (
    <div className=" relative ">
      <input
        type="text"
        id='"form-subscribe-Filter'
        className="rounded-lg border-green-400- flex-initial w-32 appearance-none border border-green-300  py-2 pl-4 pr-10 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
        placeholder={placeholder}
      />
      <span className="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="absolute inset-y-1 right-1 z-20 hidden w-8 h-8 ml-4 text-green-500 pointer-events-none  group-hover:text-green-400 sm:block"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
          />
        </svg>
      </span>
    </div>
  );
};
