import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../../Layout/HeaderContainer';

import { PageContainer } from 'src/modules/accounting/components/PageContainer';

export const YeartoDate = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        link1="Reports"
        link2="Profit and Loss"
        to3="/reports/profit-and-loss/year-to-date"
        link3="Year to Date"
      />
      {/* change this to main content */}
      <HeaderContainer>This is Year to Date Page</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
