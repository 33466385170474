import * as Yup from 'yup';

import { TableViewModel } from './common/TableViewModel';

export interface CustomerViewModel {
  id: number;
  name: string;
  notes?: string;
  accountNumber: string;
  website: string;
  shipToName: string;
  shipToAddress1: string;
  shipToAddress2?: string;
  shipToAddressCountry: string;
  shipToAddressState: string;
  shipToPhone?: string;
  shipToRemarks?: string;
}

export interface CustomerTableViewModel extends TableViewModel {
  data: CustomerViewModel[];
}

export const customerDefaultValue: CustomerViewModel = {
  id: 0,
  name: '',
  notes: '',
  accountNumber: '',
  website: '',
  shipToName: '',
  shipToAddress1: '',
  shipToAddress2: '',
  shipToAddressCountry: '',
  shipToAddressState: '',
  shipToPhone: '',
  shipToRemarks: '',
};

export const customerTableDefaultValue: CustomerTableViewModel = {
  data: [],
  page: 1,
  pageSize: 10,
  total: 0,
  totalPages: 0,
};

export const CustomerValidation = Yup.object().shape({
  name: Yup.string()
    .max(100, 'Name exceeds the maximum length of 100 characters')
    .required('Name is Required'),
  notes: Yup.string()
    .max(1000, 'Notes exceeds the maximum length of 1000 characters')
    .nullable(),
  accountNumber: Yup.string()
    .max(100, 'Account Number exceeds the maximum length of 100 characters')
    .required('Account Number is Required'),
  website: Yup.string()
    .max(100, 'Website exceeds the maximum length of 100 characters')
    .required('Website is Required'),
  shipToName: Yup.string()
    .max(100, 'Ship To Name exceeds the maximum length of 100 characters')
    .required('Ship To Name is Required'),
  shipToAddress1: Yup.string()
    .max(500, 'Ship To Address1 exceeds the maximum length of 500 characters')
    .required('Ship To Address1 is Required'),
  shipToAddress2: Yup.string()
    .max(500, 'Ship To Address2 exceeds the maximum length of 500 characters')
    .nullable(),
  shipToAddressCountry: Yup.string()
    .max(
      100,
      'Ship To Address Country exceeds the maximum length of 100 characters'
    )
    .required('Ship To Address Country is Required'),
  shipToAddressState: Yup.string()
    .max(
      100,
      'Ship To Address State exceeds the maximum length of 100 characters'
    )
    .required('Ship To Address State is Required'),
  shipToPhone: Yup.string()
    .max(50, 'Ship To Phone exceeds the maximum length of 50 characters')
    .nullable(),
  shipToRemarks: Yup.string()
    .max(1000, 'Ship To Remarks exceeds the maximum length of 1000 characters')
    .nullable(),
});
