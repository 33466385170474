import React from 'react';

interface Props {
  children?: React.ReactNode;
}

export const HeaderContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className="flex font-bold text-4xl text-black mt-4 p-4 border-b border-gray-300 ">
      {children}
    </div>
  );
};
