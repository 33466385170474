import { PageContainer } from '../../components/PageContainer';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

export const BankConnections = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        link1="Accounting"
        to2="/accounting/bank-connections"
        link2="Bank Connections"
      />
      {/* chane to main content */}
      <HeaderContainer> This is Bank Connection Page </HeaderContainer>
      {/* chane to main content */}
    </PageContainer>
  );
};
