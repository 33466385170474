import React from 'react';

import { ListActionButton } from '@components/ActionButtons/ListActionButton';
import { PencilIcons } from '@components/ButtonSwitchContainer/React-Icon/PencilIcons';
import { TrashIcons } from '@components/ButtonSwitchContainer/React-Icon/TrashIcons';
import { ViewIcons } from '@components/ButtonSwitchContainer/React-Icon/ViewIcons';

interface ItemsProps {
  item: {
    id: string;
    items: string;
    description: string;
    qty: string;
    price: string;
    account: string;
    tax: string;
    amount: string;
  };
  className?: string;
}

export const BillItemsList: React.FC<ItemsProps> = ({ item, className }) => {
  return (
    <tr className={className} key={item.id}>
      <td>{item.items}</td>
      <td>{item.description}</td>
      <td>{item.qty}</td>
      <td>{item.price}</td>
      <td>{item.account}</td>
      <td>{item.tax}</td>
      <td>{item.amount}</td>
      <td className="flex justify-center pb-3 space-x-2">
        <ListActionButton to={''}>
          <ViewIcons height="24" width="24" />
        </ListActionButton>

        <ListActionButton to={''}>
          <PencilIcons height="24" width="24" />
        </ListActionButton>

        <ListActionButton to={''}>
          <TrashIcons height="24" width="24" />
        </ListActionButton>
      </td>
    </tr>
  );
};
