/* eslint-disable import/no-unresolved */
import { useState, useEffect } from 'react';

import { DropDownContainer } from './DropDownContainer';
import { DropDownItems } from './DropDownItems';
import { DropDownMenuContainer } from './DropDownMenuContainer';
import { DropDownMenuItems } from './DropDownMenuItems';
import { HandleClicker } from './HandleClicker';
import { SideBarContainer } from './SideBarContainer';
import { SideBarMenuContainer } from './SideBarMenuContainer';

import { AccountingIcons } from '@components/ButtonSwitchContainer/React-Icon/AccountingIcons';
import { DashBoardIcons } from '@components/ButtonSwitchContainer/React-Icon/DashBoard';
import { DropDownIcon } from '@components/ButtonSwitchContainer/React-Icon/DropDownIcon';
import { PurchaseIcons } from '@components/ButtonSwitchContainer/React-Icon/PurchaseIcons';
import { ReportsIcons } from '@components/ButtonSwitchContainer/React-Icon/ReportIcons';
import { RevenueIcons } from '@components/ButtonSwitchContainer/React-Icon/RevenueIcons';
import { SettingsIcon } from '@components/ButtonSwitchContainer/React-Icon/SettingsIcon';
import { Simpacc } from '@components/ButtonSwitchContainer/React-Icon/Simpacc';

export const SideBar = () => {
  const [activeMenu, setActiveMenu] = useState(
    // false
    localStorage.getItem('activeMenu') || null
  );
  const [activeSubMenu, setActiveSubMenu] = useState(
    false
    // localStorage.getItem('activeSubMenu') || null
  );
  const [activeSubMenuMenu, setActiveSubMenuMenu] = useState(
    false
    // localStorage.getItem('activeSubMenuMenu') || null
  );
  const [showRevenue, setRevenue] = useState(
    false
    // localStorage.getItem('showRevenue') === 'true' || false
  );
  const [showPurchase, setPurchase] = useState(
    false
    // localStorage.getItem('showPurchase') === 'true' || false
  );
  const [showAccounting, setAccounting] = useState(
    false
    // localStorage.getItem('showAccounting') === 'true' || false
  );
  const [showReport, setReport] = useState(
    false
    // localStorage.getItem('showReport') === 'true' || false
  );
  const [showProfit, setProfit] = useState(false);

  const [activeReport, setActiveReport] = useState(
    false
    // localStorage.getItem('activeReport') || null
  );

  const handleDropDownClick = (menu) => {
    if (menu === 'revenue') {
      setRevenue(!showRevenue);
      localStorage.setItem('showRevenue', !showRevenue);
    } else if (menu === 'purchase') {
      setPurchase(!showPurchase);
      localStorage.setItem('showPurchase', !showPurchase);
    } else if (menu === 'accounting') {
      setAccounting(!showAccounting);
      localStorage.setItem('showAccounting', !showAccounting);
    } else if (menu === 'reports') {
      setReport(!showReport);
      localStorage.setItem('showReport', !showReport);

      setProfit(!showProfit);
      localStorage.setItem('showProfit', !showProfit);
    }
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    setActiveSubMenu(false);
    setActiveReport(menu);
    setActiveSubMenuMenu(false);
    localStorage.setItem('activeMenu', menu);
  };

  const handleReportMenu = (menu) => {
    setActiveMenu(menu);
    setActiveSubMenu(false);
    setActiveReport(menu);
    setProfit(false);
    localStorage.setItem('activeMenu', menu);
  };

  const handleSubMenuClick = (menu, mainMenu) => {
    setActiveSubMenu(menu);
    setActiveSubMenuMenu(false);
    setActiveReport(false);
    setActiveMenu(mainMenu);
    localStorage.setItem('activeSubMenu', menu);
  };

  const handleSubMenuMenuClick = (menu, mainMenu) => {
    setActiveSubMenuMenu(menu);
    activeMenuClass(true);
    setActiveSubMenu(false);
    setActiveMenu('reports');
    setActiveReport(mainMenu);
    localStorage.setItem('activeSubMenuMenu', menu);
  };

  const handleReportDropDown = (menu, mainMenu) => {
    setProfit(!showProfit);
    setActiveSubMenuMenu(menu);
    activeMenuClass(true);
    setActiveMenu('reports');
    setActiveReport(mainMenu);
    localStorage.setItem('showProfit', menu);
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('activeMenu');
    };
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem('activeSubMenu');
    };
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem('activeSubMenuMenu');
    };
  }, []);

  const reportDropDownMenuClass = (menu) =>
    activeReport === menu
      ? 'flex justify py-2 font-bold text-sm text-black text-sm px-4 w-full space-x-2 bg-[#EEFFE7]'
      : 'flex justify px-4 py-2 hover:font-bold w-full text-sm text-[#58993C] hover:text-black space-x-2 hover:bg-[#EEFFE7]';

  const activeSubMenuClass = (menu) =>
    activeSubMenu === menu
      ? 'flex justify py-0.5 font-bold text-sm text-black text-sm px-4 w-full'
      : 'flex justify px-4 py-0.5 hover:font-bold w-full text-sm text-[#58993C] hover:text-black';

  const activeSubMenuMenuClass = (menu) =>
    activeSubMenuMenu === menu
      ? 'flex justify py-0.5 font-bold text-sm text-black text-sm px-4 w-full'
      : 'flex justify px-4 py-0.5 hover:font-bold w-full text-sm text-[#58993C] hover:text-black';

  const activeMenuClass = (menu) =>
    activeMenu === menu
      ? 'shadow-md text-white bg-[#58993C] fill-[#F9FEF3] py-0.5 font-semibold text-sm px-4 w-full space-y-3 rounded-xl'
      : 'hover:shadow-md text-[#2B461B] fill-[#27401A] px-4 py-0.5 hover:bg-[#EEFFE7] w-full font-md text-sm space-y-3 rounded-xl';

  return (
    <>
      <SideBarContainer>
        <div className="flex justify-center space-x-3 py-6">
          <Simpacc height="75" width="50" />
          <h1 className="font-md text-lg text-[#58993C]">simpacc</h1>
        </div>

        <SideBarMenuContainer>
          <HandleClicker
            onClick={() => handleMenuClick('dashboard')}
            to="dashboard"
          >
            <div className={`${activeMenuClass('dashboard')}`}>
              <div className="flex space-x-3">
                <DashBoardIcons height="51" width="37" />
                <h1 className="flex items-center">Dashboard</h1>
              </div>
            </div>
          </HandleClicker>
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <button className="w-full" onClick={() => handleMenuClick('revenue')}>
            <div className={`${activeMenuClass('revenue')}`}>
              <div
                className="flex justify-between"
                onClick={() => handleDropDownClick('revenue')}
              >
                <div className="flex space-x-3">
                  <RevenueIcons height="51" width="37" />
                  <h1 className="flex items-center">Revenue and Collections</h1>
                </div>
                <button className="">
                  <DropDownIcon height="12" width="8" />
                </button>
              </div>
            </div>
          </button>
          {showRevenue && (
            <DropDownContainer>
              <DropDownItems
                to="/revenue-and-collections/estimate-and-quotes"
                onClick={() => handleSubMenuClick('estimate', 'revenue')}
                className={`${activeSubMenuClass('estimate')}`}
              >
                Estimate and Quotes
              </DropDownItems>

              <DropDownItems
                to="/revenue-and-collections/invoice"
                onClick={() => handleSubMenuClick('invoice', 'revenue')}
                className={`${activeSubMenuClass('invoice')}`}
              >
                Invoice
              </DropDownItems>
              <DropDownItems
                to="/revenue-and-collections/collection-set-up"
                onClick={() => handleSubMenuClick('collectionSetup', 'revenue')}
                className={`${activeSubMenuClass('collectionSetup')}`}
              >
                Collection Set-up
              </DropDownItems>
              <DropDownItems
                to="/revenue-and-collections/customer-statements"
                onClick={() =>
                  handleSubMenuClick('customerStatements', 'revenue')
                }
                className={`${activeSubMenuClass('customerStatements')}`}
              >
                Customer Statements
              </DropDownItems>
              <DropDownItems
                to="/revenue-and-collections/customers"
                onClick={() => handleSubMenuClick('customer', 'revenue')}
                className={`${activeSubMenuClass('customer')}`}
              >
                Customers
              </DropDownItems>
              <DropDownItems
                to="/revenue-and-collections/products-and-services"
                onClick={() =>
                  handleSubMenuClick('productsAndServices', 'revenue')
                }
                className={`${activeSubMenuClass('productsAndServices')}`}
              >
                Products and Services
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <button
            className="w-full"
            onClick={() => handleMenuClick('purchase and payment')}
          >
            <div className={`${activeMenuClass('purchase and payment')}`}>
              <div
                className="flex justify-between"
                onClick={() => handleDropDownClick('purchase')}
              >
                <div className="flex space-x-4">
                  <PurchaseIcons height="51" width="37" />
                  <h1 className="flex items-center">Purchase and Payments</h1>
                </div>
                <button className="">
                  <DropDownIcon height="12" width="8" />
                </button>
              </div>
            </div>
          </button>
          {showPurchase && (
            <DropDownContainer>
              <DropDownItems
                to="/purchase-and-payments/bills"
                onClick={() =>
                  handleSubMenuClick('bills', 'purchase and payment')
                }
                className={`${activeSubMenuClass('bills')}`}
              >
                Bills
              </DropDownItems>
              <DropDownItems
                to="/purchase-and-payments/payment-set-up"
                onClick={() =>
                  handleSubMenuClick('paymentSetup', 'purchase and payment')
                }
                className={`${activeSubMenuClass('paymentSetup')}`}
              >
                Payment Set-up
              </DropDownItems>
              <DropDownItems
                to="/purchase-and-payments/vendors"
                onClick={() =>
                  handleSubMenuClick('vendors', 'purchase and payment')
                }
                className={`${activeSubMenuClass('vendors')}`}
              >
                Vendors
              </DropDownItems>
              <DropDownItems
                to="/purchase-and-payments/products-and-services"
                onClick={() =>
                  handleSubMenuClick(
                    'productandServices',
                    'purchase and payment'
                  )
                }
                className={`${activeSubMenuClass('productandServices')}`}
              >
                Product and Services
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <button
            className="w-full"
            onClick={() => handleMenuClick('accounting')}
          >
            <div className={`${activeMenuClass('accounting')}`}>
              <div
                className="flex justify-between"
                onClick={() => handleDropDownClick('accounting')}
              >
                <div className="flex space-x-4">
                  <AccountingIcons height="51" width="37" />
                  <h1 className="flex items-center">Accounting</h1>
                </div>
                <button className="">
                  <DropDownIcon height="12" width="8" />
                </button>
              </div>
            </div>
          </button>
          {showAccounting && (
            <DropDownContainer>
              <DropDownItems
                to="/accounting/transactions"
                onClick={() => handleSubMenuClick('transaction', 'accounting')}
                className={`${activeSubMenuClass('transaction')}`}
              >
                Transactions
              </DropDownItems>
              <DropDownItems
                to="/accounting/recon"
                onClick={() => handleSubMenuClick('recon', 'accounting')}
                className={`${activeSubMenuClass('recon')}`}
              >
                Recon
              </DropDownItems>
              <DropDownItems
                to="/accounting/charts-of-accounts-to-date"
                onClick={() => handleSubMenuClick('charts', 'accounting')}
                className={`${activeSubMenuClass('charts')}`}
              >
                Charts of Accounts to Date
              </DropDownItems>
              <DropDownItems
                to="/accounting/bank-connections"
                onClick={() =>
                  handleSubMenuClick('bankConnection', 'accounting')
                }
                className={`${activeSubMenuClass('bankConnection')}`}
              >
                Bank Connections
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <button
            className="w-full"
            onClick={() => handleReportMenu('reports')}
          >
            <div className={`${activeMenuClass('reports')}`}>
              <div
                className="flex justify-between"
                onClick={() => handleDropDownClick('reports')}
              >
                <div className="flex space-x-4">
                  <ReportsIcons height="51" width="37" />
                  <h1 className="flex items-center">Reports</h1>
                </div>
                <button className="">
                  <DropDownIcon height="12" width="8" />
                </button>
              </div>
            </div>
          </button>
          {showReport && (
            <DropDownContainer>
              <button
                className={`${reportDropDownMenuClass('profitAndLoss')}`}
                onClick={() => handleReportDropDown('profitAndLoss')}
              >
                <div className="flex space-x-2">
                  <div className="flex">
                    <span className="w-16" />
                    <h1 className="text-xs">Profit and Loss</h1>
                  </div>
                  <button className="items-center">
                    <DropDownIcon height="12" width="8" />
                  </button>
                </div>
              </button>
            </DropDownContainer>
          )}
          {showProfit && (
            <DropDownMenuContainer>
              <DropDownMenuItems
                to="/reports/profit-and-loss/comparative"
                onClick={() =>
                  handleSubMenuMenuClick('comparative', 'profitAndLoss')
                }
                className={`${activeSubMenuMenuClass('comparative')}`}
              >
                Comparative
              </DropDownMenuItems>
              <DropDownMenuItems
                to="/reports/profit-and-loss/quarter"
                onClick={() =>
                  handleSubMenuMenuClick('quarter', 'profitAndLoss')
                }
                className={`${activeSubMenuMenuClass('quarter')}`}
              >
                Quarter
              </DropDownMenuItems>
              <DropDownMenuItems
                to="/reports/profit-and-loss/year-to-date"
                onClick={() =>
                  handleSubMenuMenuClick('yearToyear', 'profitAndLoss')
                }
                className={`${activeSubMenuMenuClass('yearToyear')}`}
              >
                Year to Date
              </DropDownMenuItems>
              <DropDownMenuItems
                to="/reports/profit-and-loss/monthly"
                onClick={() =>
                  handleSubMenuMenuClick('monthly', 'profitAndLoss')
                }
                className={`${activeSubMenuMenuClass('monthly')}`}
              >
                Monthly
              </DropDownMenuItems>
            </DropDownMenuContainer>
          )}
        </SideBarMenuContainer>

        <div className="flex-1 px-1.5">
          <HandleClicker
            onClick={() => handleMenuClick('settings')}
            to="/settings"
          >
            <div className={`${activeMenuClass('settings')}`}>
              <div className="flex space-x-3">
                <SettingsIcon height="51" width="37" />
                <h1 className="flex items-center">Settings</h1>
              </div>
            </div>
          </HandleClicker>
        </div>
      </SideBarContainer>
    </>
  );
};
