export const api = {
  BASE_URL:
    process.env.name === 'APPLICATION_PROD'
      ? 'https://accounting-api-dev.simpacc.ca'
      : process.env.name === 'APPLICATION_LOCAL'
      ? 'https://localhost:7441'
      : 'https://accounting-api-dev.simpacc.ca',
  CONTACTS_ENDPOINT: '/api/contacts',
  CUSTOMERS_ENDPOINT: '/api/customers',
};
