interface Props {
  placeholder: string;
}

export const InputSearchbar: React.FC<Props> = ({ placeholder }) => {
  return (
    <div className="relative">
      <button type="submit">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="absolute inset-y-1 right-1 z-20 hidden w-8 h-8 ml-4 text-green-500 pointer-events-none group-hover:text-green-400 sm:block"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </button>

      <input
        type="text"
        id='"form-subscribe-Filter'
        className="rounded-lg border-green-400 flex-initial appearance-none border w-52 py-2 pl-4 pr-10 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
        placeholder={placeholder}
      />
    </div>
  );
};
