import { PageContainer } from '../../components/PageContainer';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

export const Recon = () => {
  return (
    <PageContainer>
      <BreadCrumbs link1="Accounting" to2="/accounting/recon" link2="Recon" />
      {/* change this to main content */}
      <HeaderContainer>This is of Recon Page</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
