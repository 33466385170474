import React from 'react';

import { ListActionButton } from '@components/ActionButtons/ListActionButton';
import { PencilIcons } from '@components/ButtonSwitchContainer/React-Icon/PencilIcons';
import { TrashIcons } from '@components/ButtonSwitchContainer/React-Icon/TrashIcons';
import { ViewIcons } from '@components/ButtonSwitchContainer/React-Icon/ViewIcons';

interface ItemsProps {
  item: {
    id: string;
    date: string;
    dueDate: string;
    currency: string;
    company: string;
    poNumber: string;
    amount: string;
  };
  className?: string;
}

export const ItemList: React.FC<ItemsProps> = ({ item, className }) => {
  return (
    <tr className={className} key={item.id}>
      <td>{item.date}</td>
      <td>{item.dueDate}</td>
      <td>{item.currency}</td>
      <td>{item.company}</td>
      <td>{item.poNumber}</td>
      <td>{item.amount}</td>
      <td className="flex justify-center space-x-2 py-3 border-b border-gray-200">
        <ListActionButton to={''}>
          <ViewIcons height="24" width="24" />
        </ListActionButton>

        <ListActionButton to={''}>
          <PencilIcons height="24" width="24" />
        </ListActionButton>

        <ListActionButton to={''}>
          <TrashIcons height="24" width="24" />
        </ListActionButton>
      </td>
    </tr>
  );
};
