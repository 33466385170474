import { useState } from 'react';

import { InputTextsArea } from '../../components/InputTextsArea';
import { MessageTextArea } from '../../components/MessageTextArea';
import { PageContainer } from '../../components/PageContainer';
import { SelectionsOptions } from '../../components/SelectionsOptions';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';

import { Pagination } from '@components/ActionButtons/Pagination';

// const Bills {''}

export const Bills = () => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDueDate, setSelectedDueDate] = useState('');
  const [error, setError] = useState(false);

  const today = new Date().toISOString().split('T')[0];

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);

    if (selectedDueDate && newDate > selectedDueDate) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleDueDateChange = (e) => {
    const newDueDate = e.target.value;
    setSelectedDueDate(newDueDate);

    if (selectedDate && selectedDate > newDueDate) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <PageContainer>
      <BreadCrumbs
        link1="Purchase and Payments"
        to2="/purchase-and-payments/bills"
        link2="Bills"
      />
      <div className="grid justify-items-center pt-4">
        <h2 className="sr-only">Steps</h2>
        {/* Steps code here */}
        <div className="pt-4 relative after:absolute after:inset-x-0 after:top-1/2 after:block after:h-0.5 after:-translate-y-1/2 after:rounded-lg after:bg-gray-100">
          <ol className="relative space-x-52 z-10 flex justify-between text-sm font-medium text-gray-500">
            <li className="flex items-center gap-2 bg-white pb-4">
              <span className="h-6 w-6 rounded-full border-green-400 bg-green-600 border  text-center text-[10px]/6 font-bold text-white">
                1
              </span>
            </li>

            <li className="flex items-center gap-2 bg-white pb-4">
              <span className="h-6 w-6 rounded-full bg-green-600 border-green-400  border text-center text-[10px]/6 font-bold text-white">
                2
              </span>
            </li>

            <li className="flex items-center gap-2 bg-white pb-4">
              <span className="h-6 w-6 rounded-full border-green-600 border text-center text-[10px]/6 font-bold text-black">
                3
              </span>
            </li>
          </ol>
        </div>
      </div>
      <div>
        <h1 className="text-3xl pt-8 text-center text-green-700">
          Bill Details
        </h1>
      </div>

      <div className="grid grid-cols-2 gap-48 pt-10 px-52">
        {/* Left column */}
        <div className="space-y-6">
          <SelectionsOptions
            label="Currency"
            selection={[
              {
                value: 'usd',
                text: 'US Dollar',
              },
              {
                value: 'eur',
                text: 'Euro',
              },
              {
                value: 'gbp',
                text: 'British Pound',
              },
            ]}
            placeholder="Select Currency"
          />

          <div>
            <SelectionsOptions
              label="Cost Center"
              selection={[
                {
                  value: 'Dog',
                  text: 'Dog',
                },
                {
                  value: 'Cat',
                  text: 'Cat',
                },
              ]}
              placeholder="Select Cost Center"
            />
          </div>
        </div>

        {/* Right column */}
        <div className="flex justify-end ">
          <div className="space-y-6">
            <div>
              <InputTextsArea label="PO Number" placeholder="PO Number" />
            </div>
            <div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Date
                </label>
                <input
                  type="date"
                  className={`border ${
                    error ? 'border-red-500' : 'border-green-300'
                  } focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm rounded-md`}
                  min={today}
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium pt-6 text-gray-700">
                  Due Date
                </label>
                <input
                  type="date"
                  className={`border ${
                    error ? 'border-red-500' : 'border-green-300'
                  } focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm rounded-md`}
                  min={selectedDate || today} // Not allowed to pick dates before selectedDate or today
                  value={selectedDueDate}
                  onChange={handleDueDateChange}
                />
              </div>
            </div>
            <div>
              <InputTextsArea label="Preference" placeholder="Preference" />
            </div>
          </div>
        </div>
      </div>

      {/* Remarks input code here */}
      <div>
        <div className="pt-6 px-52">
          <MessageTextArea
            label="Remarks"
            rows={4}
            placeholder="Add your message here . . ."
          />
        </div>
      </div>

      <div className="flex justify-end py-10 pr-48">
        <Pagination to="/purchase-and-payments/vendors">Previous</Pagination>
        <Pagination to="/purchase-and-payments/bill/bill-items">
          Next
        </Pagination>
      </div>
    </PageContainer>
  );
};
