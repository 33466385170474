interface Props {
  children?: React.ReactNode;
}

export const TopRowsContainer: React.FC<Props> = ({ children }) => {
  return (
    <>
      <th className="bg-green-300 py-3 text-sm font-semibold text-center text-gray-800 uppercase  border-b border-gray-200">
        {children}
      </th>
    </>
  );
};
