interface SelectOptions {
  selected?: boolean;
  value: string;
  text: string;
}

type Props = {
  label?: string;
  selection: SelectOptions[];
  placeholder: string;
};

export const SelectionsOptions: React.FC<Props> = ({
  label,
  selection,
  placeholder,
}) => {
  return (
    <>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <select className="text-green-700 w-96 px-3 py-2 text-sm bg-white border border-green-300 rounded-md shadow-sm  focus:outline-none focus:ring-green-500 focus:border-green-500">
          <option value="placeholder" disabled selected>
            {placeholder}
          </option>
          {selection.map((option, index) => (
            <option key={index} value={option.value} selected={option.selected}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
