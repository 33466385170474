import { Link } from 'react-router-dom';

interface Props {
  to: string;
  className: any;
  onClick: any;
  children?: React.ReactNode;
}

export const DropDownItems: React.FC<Props> = ({
  to,
  children,
  onClick,
  className,
}) => {
  return (
    <div className={className}>
      <span className="w-16" />
      <Link to={to} onClick={onClick}>
        {children}
      </Link>
    </div>
  );
};
