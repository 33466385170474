interface Props {
  children?: React.ReactNode;
}

export const PageContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className="bg-white pt-4 h-full w-full shadow-lg border rounded-lg">
      {children}
    </div>
  );
};
