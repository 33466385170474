import { Link } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
  to: string;
}

export const Pagination: React.FC<Props> = ({ children, to }) => {
  return (
    <Link
      type="button"
      className="py-2 px-10 mr-4 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
      to={to}
    >
      {children}
    </Link>
  );
};
