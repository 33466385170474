import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../../Layout/HeaderContainer';

import { PageContainer } from 'src/modules/accounting/components/PageContainer';

export const Comparative = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        link1="Reports"
        link2="Profit and Loss"
        to3="/reports/profit-and-loss/comparative"
        link3="Comparative"
      />
      {/* change this to main content */}
      <HeaderContainer>This is Comparative Page</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
