import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

import { PageContainer } from '../../components/PageContainer';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend
);

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

export const DashBoard = () => {
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],

    datasets: [
      {
        label: 'Monthly Sales',
        data: [150, 230, 180, 280, 200, 200],

        tension: 0.4,
        borderWidth: 2,
        fill: true,
        pointRadius: 0,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Weekly Sales',
        data: [33, 25, 35, 51, 54, 250],
        borderColor: '#742774',
        tension: 0.4,
        borderWidth: 2,
        fill: true,
        pointRadius: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        display: true,
      },
      y: {
        display: true,
      },
    },
    plugins: {
      filler: {
        propagate: true,
      },
    },
  };

  const data1 = {
    labels: [
      'Category 1',
      'Category 2',
      'Category 3',
      'Category 4',
      'Category 5',
      'Category 5',
      'Category 5',
    ],
    datasets: [
      {
        data: [12, 19, 3, 5, 2, 17, 19],
        backgroundColor: [
          '#F87171',
          '#60A5FA',
          '#34D399',
          '#A78BFA',
          '#FBBF24',
          '#F87171',
          '#60A5FA',
        ],
      },
    ],
  };

  const options1 = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <PageContainer>
        <BreadCrumbs to="/" link1="Dashboard" />
        <HeaderContainer>
          <div className="">
            <div className="p-4 m-2 text-center  w-full">
              <ul>
                <li className="inline-block p-2">
                  <div className="h-24 w-48 bg-[#F87171] p2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-cart-check-fill inline-block text-white m-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />{' '}
                    </svg>
                    <p className="text-white">Sales</p>
                    <p className="text-white">73,000.00</p>
                  </div>
                </li>
                <li className="inline-block p-2">
                  <div className="h-24 w-48 bg-[#60A5FA] p2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-cart-check-fill inline-block text-white m-2"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"
                      />{' '}
                    </svg>
                    <p className="text-white">Revenue</p>
                    <p className="text-white">50,000.00</p>
                  </div>
                </li>
                <li className="inline-block p-2">
                  <div className="h-24 w-48 bg-[#34D399] p2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-cart-check-fill inline-block text-white m-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 0a.5.5 0 0 1 .5.5V2h10V.5a.5.5 0 0 1 1 0v15a.5.5 0 0 1-1 0V15H3v.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 .5-.5zM3 14h10v-3H3v3zm0-4h10V7H3v3zm0-4h10V3H3v3z" />{' '}
                    </svg>
                    <p className="text-white">Annual</p>
                    <p className="text-white">3,000.000.00</p>
                  </div>
                </li>
                <li className="inline-block p-2">
                  <div className="h-24 w-48 bg-[#A78BFA] p2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-cart-check-fill inline-block text-white m-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 4.5V9h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v7H2V2a2 2 0 0 1 2-2h5.5L14 4.5zM13 12h1v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2h1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-2zM.5 10a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H.5z" />{' '}
                    </svg>
                    <p className="text-white">Tax</p>
                    <p className="text-white">43,000.00</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="flex items-center ">
              <div className="w-full md:w-1/2">
                <div className="p-4">
                  <Line
                    data={data}
                    options={options}
                    className="border rounded-lg shadow-lg p-4 "
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="p-4">
                  <Bar
                    data={data1}
                    options={options1}
                    className="border rounded-lg shadow-lg p-4  "
                  ></Bar>
                </div>
              </div>
            </div>
          </div>
        </HeaderContainer>
      </PageContainer>
    </>
  );
};
