type Props = {
  label: string;
  placeholder: string;
};

export const InputTextsArea: React.FC<Props> = ({ label, placeholder }) => {
  return (
    <>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <input
          className="text-green-700 w-96 px-3 py-2 text-sm bg-white border border-green-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
          placeholder={placeholder}
        />
      </div>
    </>
  );
};
