type Props = {
  label: string;
  placeholder: string;
  rows?: number;
};

export const MessageTextArea: React.FC<Props> = ({
  label,
  placeholder,
  rows,
}) => {
  return (
    <>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <textarea
          className="w-full px-3 py-2 text-sm bg-white border border-green-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
          rows={rows}
          placeholder={placeholder}
        />
      </div>
    </>
  );
};
