export const list = [
  {
    id: '1',
    date: 'Dec 15,2023',
    dueDate: 'Jan 7,2024',
    currency: '$ USD',
    company: 'Accubooks Inc.',
    poNumber: '3000-95192',
    amount: '$1,000.00',
  },

  {
    id: '1',
    date: 'Dec 15,2023',
    dueDate: 'Jan 7,2024',
    currency: '$ USD',
    company: 'Accubooks Inc.',
    poNumber: '3000-95192',
    amount: '$1,000.00',
  },
  {
    id: '1',
    date: 'Dec 15,2023',
    dueDate: 'Jan 7,2024',
    currency: '$ USD',
    company: 'Accubooks Inc.',
    poNumber: '3000-95192',
    amount: '$1,000.00',
  },

  {
    id: '1',
    date: 'Dec 15,2023',
    dueDate: 'Jan 7,2024',
    currency: '$ USD',
    company: 'Accubooks Inc.',
    poNumber: '3000-95192',
    amount: '$1,000.00',
  },
  {
    id: '1',
    date: 'Dec 15,2023',
    dueDate: 'Jan 7,2024',
    currency: '$ USD',
    company: 'Accubooks Inc.',
    poNumber: '3000-95192',
    amount: '$1,000.00',
  },
  {
    id: '1',
    date: 'Dec 15,2023',
    dueDate: 'Jan 7,2024',
    currency: '$ USD',
    company: 'Accubooks Inc.',
    poNumber: '3000-95192',
    amount: '$1,000.00',
  },
  {
    id: '1',
    date: 'Dec 15,2023',
    dueDate: 'Jan 7,2024',
    currency: '$ USD',
    company: 'Accubooks Inc.',
    poNumber: '3000-95192',
    amount: '$1,000.00',
  },
];
