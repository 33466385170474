import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { Box } from '@components/Box';
import { BoxBodyContainer } from '@components/Box/BoxBodyContainer';

export const Customer: React.FC = () => {
  return (
    <>
      <Box>
        <BoxBodyContainer>
          <BreadCrumbs
            link1="Revenue and Collections"
            to2="/revenue-and-collections/customers"
            link2="Customer"
          />
        </BoxBodyContainer>
        <BoxBodyContainer>
          <Outlet />
        </BoxBodyContainer>
      </Box>
    </>
  );
};
