import { Link } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
  to: string;
}

export const ListActionButton: React.FC<Props> = ({ children, to }) => {
  return (
    <Link to={to}>
      <div className="text-green-600 hover:text-green-900">{children}</div>
    </Link>
  );
};
