import { createHashRouter } from 'react-router-dom';

import App from './App';
import { BankConnections } from './pages/Accounting/BankConnections';
import { ChartsofAccountsToDate } from './pages/Accounting/ChartsOfAccountsToDate';
import { Recon } from './pages/Accounting/Recon';
import { Transactions } from './pages/Accounting/Transactions';
import { DashBoard } from './pages/Dashboard/Dashboard';
import { Home } from './pages/Home';
import { Bills } from './pages/PurchaseandPayments/Bills';
import { BillItems } from './pages/PurchaseandPayments/Bills/BillItems';
import { PaymentsSetup } from './pages/PurchaseandPayments/PaymentsSetUp';
import { ProductsAndServicesonPurchase } from './pages/PurchaseandPayments/ProductsAndServices';
import { Vendor } from './pages/PurchaseandPayments/Vendors';
import { Comparative } from './pages/Reports/ProfitAndLoss/Comparative';
import { Monthly } from './pages/Reports/ProfitAndLoss/Monthly';
import { Quarter } from './pages/Reports/ProfitAndLoss/Quarter';
import { YeartoDate } from './pages/Reports/ProfitAndLoss/YearToDate';
import { CollectionSetUp } from './pages/RevenueAndCollections/CollectionSetUp';
import {
  Customer,
  CustomerCreate,
  CustomerDetails,
  CustomerEdit,
  CustomerList,
} from './pages/RevenueAndCollections/Customers';
import { CustomerStatements } from './pages/RevenueAndCollections/CustomerStatements';
import { EstimateAndQuotes } from './pages/RevenueAndCollections/EstimateAndQuotes';
import { Invoice } from './pages/RevenueAndCollections/Invoice';
import { ProductsAndServices } from './pages/RevenueAndCollections/ProductsAndServices';
import { RevenueAndCollections } from './pages/RevenueAndCollections/RevenueAndCollections';
import { Settings } from './pages/Settings/Settings';

const revenueAndCollections = {
  path: 'revenue-and-collections',
  element: <RevenueAndCollections />,
  children: [
    {
      path: 'customers',
      element: <Customer />,
      children: [
        { index: true, element: <CustomerList /> },
        { path: 'create', element: <CustomerCreate /> },
        { path: ':id/details', element: <CustomerDetails /> },
        { path: ':id/edit', element: <CustomerEdit /> },
      ],
    },
    { path: 'customer-statements', element: <CustomerStatements /> },
    { path: 'collection-set-up', element: <CollectionSetUp /> },
    { path: 'estimate-and-quotes', element: <EstimateAndQuotes /> },
    { path: 'invoice', element: <Invoice /> },
    { path: 'products-and-services', element: <ProductsAndServices /> },
  ],
};

export const AppRouter = createHashRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <Home /> },
      { path: 'dashboard', element: <DashBoard /> },
      revenueAndCollections,
      {
        path: 'purchase-and-payments/bills',
        element: <Bills />,
      },
      {
        path: 'purchase-and-payments/bill/bill-items',
        element: <BillItems />,
      },
      {
        path: 'purchase-and-payments/payment-set-up',
        element: <PaymentsSetup />,
      },

      { path: 'purchase-and-payments/vendors', element: <Vendor /> },
      {
        path: 'purchase-and-payments/products-and-services',
        element: <ProductsAndServicesonPurchase />,
      },
      { path: 'accounting/transactions', element: <Transactions /> },
      { path: 'accounting/recon', element: <Recon /> },
      {
        path: 'accounting/charts-of-accounts-to-date',
        element: <ChartsofAccountsToDate />,
      },
      {
        path: 'accounting/bank-connections',
        element: <BankConnections />,
      },
      {
        path: 'reports/profit-and-loss/comparative',
        element: <Comparative />,
      },
      {
        path: 'reports/profit-and-loss/quarter',
        element: <Quarter />,
      },
      {
        path: 'reports/profit-and-loss/year-to-date',
        element: <YeartoDate />,
      },
      {
        path: 'reports/profit-and-loss/monthly',
        element: <Monthly />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
    ],
  },
]);
