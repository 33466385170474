import { PageContainer } from '../../components/PageContainer';
import { SelectionsOptions } from '../../components/SelectionsOptions';
import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';

import { Pagination } from '@components/ActionButtons/Pagination';

export const Vendor = () => {
  return (
    <>
      <PageContainer>
        <BreadCrumbs
          link1="Purchase and Payments"
          to2="/purchase-and-payments/vendors"
          link2="Vendors"
        />
        <div className="grid justify-items-center pt-4">
          <h2 className="sr-only">Steps</h2>
          <div className="pt-4 relative after:absolute after:inset-x-0 after:top-1/2 after:block after:h-0.5 after:-translate-y-1/2 after:rounded-lg after:bg-gray-100">
            <ol className="relative space-x-52 z-10 flex justify-between text-sm font-medium text-gray-500">
              <li className="flex items-center gap-2 bg-white pb-4">
                <span className="h-6 w-6 rounded-full border-green-400 bg-green-600 border text-center text-[10px]/6 font-bold text-white">
                  1
                </span>
              </li>

              <li className="flex items-center gap-2 bg-white pb-4">
                <span className="h-6 w-6 rounded-full border-green-600 border text-center text-[10px]/6 font-bold text-black">
                  2
                </span>
              </li>

              <li className="flex items-center gap-2 bg-white pb-4">
                <span className="h-6 w-6 rounded-full border-green-600 border text-center text-[10px]/6 font-bold text-black">
                  3
                </span>
              </li>
            </ol>
          </div>
        </div>
        <div className="text-3xl pt-8 text-center text-green-700">Vendor</div>
        <div className="flex justify-center pt-32">
          <SelectionsOptions
            label="Vendors"
            selection={[
              {
                value: 'dog',
                text: 'Dog',
              },
              {
                value: 'Cat',
                text: 'Cat',
              },
            ]}
            placeholder="Vendors"
          />
        </div>
        <div className="grid place-content-end pb-10 px-10 pt-48">
          <Pagination to="/purchase-and-payments/bills">
            <span className="flex">Next</span>
          </Pagination>
        </div>
      </PageContainer>
    </>
  );
};
