import { Link } from 'react-router-dom';

import { AddBillIcons } from '@components/ButtonSwitchContainer/React-Icon/AddBillIcons';

interface Props {
  children?: React.ReactNode;
  to: string;
}

export const AddRecord: React.FC<Props> = ({ children, to }) => {
  return (
    <Link
      className=" flex space-x-2 pl-10 text-green-600 hover:text-green-900"
      to={to}
    >
      <AddBillIcons height={'24'} width={'24'} />
      <span className="text-black hover:text-green-900">{children}</span>
    </Link>
  );
};
